import { BasketProvider } from "./BasketContext";
import { FavoriteProvider } from "./FavoriteContext";
import { PromocodeProvider } from "./PromocodeContext";

const AppProviders = ({ children }) => (
    <PromocodeProvider>
        <BasketProvider>
            <FavoriteProvider>{children}</FavoriteProvider>
        </BasketProvider>
    </PromocodeProvider>
    
);

export default AppProviders;
