import classNames from "classnames";

const WithTooltip = ({ children, content, position = "top" }) => {

    const tooltipStyle = classNames(
        "absolute whitespace-nowrap bg-gray-800 text-white text-sm px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300",
        "before:content-[''] before:absolute before:w-2 before:h-2 before:bg-gray-800 before:rotate-45",
        {"bottom-full left-1/2 transform -translate-x-1/2 mb-2 before:-bottom-1 before:left-1/2 before:transform before:-translate-x-1/2": position === 'top'},
        {"top-full left-1/2 transform -translate-x-1/2 mt-2 before:-top-1 before:left-1/2 before:transform before:-translate-x-1/2": position === 'bottom'},
        {"right-full top-1/2 transform -translate-y-1/2 mr-2 before:-right-1 before:top-1/2 before:transform before:-translate-y-1/2": position === 'left'},
        {"left-full top-1/2 transform -translate-y-1/2 ml-2 before:-left-1 before:top-1/2 before:transform before:-translate-y-1/2": position === 'right'},

    )
  
    return (
        <div className="relative group inline-block">
            {children}
            <div
                className={tooltipStyle}
            >
                {content}
            </div>
        </div>
    );
};
  
export default WithTooltip;