import { useContext } from "react";
import { PromocodeContext } from "../../../contexts/PromocodeContext";
import InputFieldDashed from "../../Inputs/InputFieldDashed";
import { useTranslation } from "react-i18next";
import { InputFieldDashedStates } from "../../Inputs/InputFieldDashed";

function PromocodeInput({setIsExpanded, isExpanded}) {
    const {promocode,
        removePromocode,
        updatePromocodeCode,
        appliedPromocode} = useContext(PromocodeContext)

    const {t} = useTranslation();

    const getInputState = (promocodeState) => {
        if(promocodeState === '') {
            return InputFieldDashedStates.NORMAL;
        }
        return InputFieldDashedStates.ERROR;
    }

    return (
        <InputFieldDashed
            id={"promocode"}
            name={"promocode"}
            placeholder={Boolean(appliedPromocode) ? t("Remove promocode to add a new one") : t("Add Promocode")}
            value={promocode.code}
            onChange={(e) => {
                updatePromocodeCode(e.target.value)
            }}
            maxLength="12"
            state = {getInputState(promocode.state)}
            onFocus={() => {
                removePromocode();
                setIsExpanded(true);
            }}
            onBlur={() => {setIsExpanded(false)}}
            disabled={Boolean(appliedPromocode)}
        />
    )
}

export default PromocodeInput;