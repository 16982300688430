import { Trans } from "react-i18next"
import { useState, useEffect, useRef } from "react";

function AnimatedText() {

    const [isScrolled, setIsScrolled] = useState(false);
    const hasAnimatedRef = useRef(false);
    useEffect(() => {

        const handleScroll = () => {

            setTimeout(() => {
                if(window.scrollY === 0 && hasAnimatedRef.current && (Date.now() - hasAnimatedRef.current) > 2000) {
                    console.log('hidden')
                    document.body.style.overflow = 'hidden';
                }
            }, 2000);

            if(document.body.style.overflow === 'hidden') {
                trottledScroll()
            }
            
        };

        const Animate = () => {

            setIsScrolled((prev) => {
                setTimeout(() => {
                    document.body.style.overflow = 'auto';
                    hasAnimatedRef.current = Date.now();
                }, 1000)

                return !prev;
            })
        }

        const trottledScroll = throttle(Animate, 1000)

        document.body.style.overflow = 'hidden';

        window.addEventListener("wheel", handleScroll);

        return () => {
            document.body.style.overflow = 'auto';

            window.removeEventListener("wheel", handleScroll);
        };
    }, []);

    return (
            <div className="h-12 w-full flex flex-col items-center justify-center relative row-span-2">
                <div className={`flex justify-center absolute top-1/2 transform -translate-y-1/2 transition-all duration-700 ${
                            isScrolled ? "opacity-0 scale-50 -translate-y-10" : "opacity-100 scale-100 translate-y-0"
                        }`}>
                    <Header4>
                        <Trans
                            i18nKey={"When was the last time you actually enjoyed online shopping"}
                            components={{
                                gradient: <span className="lidabro-text2" />,
                            }}
                        />
                    </Header4>
                </div>
                
                <div className={`flex justify-center absolute bottom-1/4 transform transition-all duration-700 ${
                            isScrolled ? "opacity-100 scale-100 translate-y-36 xsm:translate-y-40" : "opacity-0 scale-50 translate-y-10"
                        }`}>
                    <Header4>
                        <Trans
                            i18nKey={"Its time to humanize your online shopping experience!"}
                            components={{
                                gradient: <span className="lidabro-text2" />,
                            }}
                        />
                    </Header4>
                </div>
            </div>
        
    )
}

function Header4({children}) {
    return (
        <h4 className="w-full xsm:w-3/5 font-main font-semibold xsm:font-normal text-center text-[25px] leading-10 xsm:text-[35px] md:leading-[54px] lg:leading-[65px] lg:text-[45px] xlg:text-[65px]">
            {children}
        </h4>
    )
}

export default AnimatedText;


function throttle(fn, delay) {
    let lastCall = 0;

    return function (...args) {
        const now = new Date().getTime();
        if (now - lastCall >= delay) {
            lastCall = now;
            fn(...args);
        }
    };
}