import { LidabroLogo } from "../../../pages/Landing/Components/LidabroLogo/LidabroLogo";
import FooterLink from "./FooterLink";
import { FacebookCircleSVG } from "../../../images/SVGAssets";
import { useTranslation } from "react-i18next";
function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="max-w-lidabro-web w-full mx-auto md:p-8 pb-12">
      <div className="flex items-center justify-center flex-col sm:flex-row md:justify-between xl:px-12 gap-6 sm:gap-0">
        <LidabroLogo classes="hidden md:flex" />
        <div className="flex gap-[6px]">
          <FooterLink link="#" text={t("What is Lidabro?")} />
          <FooterLink link="#" text={t("Contact Us")} />
        </div>
        <FooterLink
          link="#"
          classes="hidden lg:flex"
          text="© 2024 Lidabro. All rights reserved."
        />
        <FooterLink link="#">
          <FacebookCircleSVG />
        </FooterLink>
      </div>
    </footer>
  );
}
export default Footer;
