import { CanIcon, CookieBiteIcon, JumpRopeIcon, VitaminsIcon, ManTshirtIcon, HexagonIcon, WomanTshirtIcon } from "../../../../Components/Icons/Icons"
import { useTranslation } from "react-i18next";
function CategoriesMask() {
    const {t} = useTranslation();
    return (
        <div 
            className="absolute -rotate-6 flex w-[2000px] h-[311px] lg:left-30 left-0 bottom-0 gap-x-3 gap-y-4 opacity-40"
        >
            <CategoryBadge>
                <CanIcon/>
                <span>{t("Proteins, Gainers")}</span>
            </CategoryBadge>
            <CategoryBadge>
                <CookieBiteIcon/>
                <span>{t("Fitness Nutrition")}</span>
            </CategoryBadge>
            <CategoryBadge>
                <JumpRopeIcon/>
                <span>{t("Accessories")}</span>
            </CategoryBadge>
            <CategoryBadge>
                <WomanTshirtIcon/>
                <span>{t("Woman's Athletic Clothing")}</span>
            </CategoryBadge>
            <CategoryBadge>
                <VitaminsIcon/>
                <span>{t("Sport Vitamins")}</span>
            </CategoryBadge>
            <CategoryBadge>
                <ManTshirtIcon/>
                <span>{t("Men's Athletic Clothing")}</span>
            </CategoryBadge>
            <CategoryBadge>
                <HexagonIcon/>
                <span>{t("Amino Acids")}</span>
            </CategoryBadge>
        </div>
    )
}

function CategoryBadge({children}) {
    return (
        <div
        className="flex text-white w-full h-fit px-6 py-6 gap-2 justify-center items-center rounded-lg bg-[rgba(255,255,255,0.1)] backdrop-blur-sm font-secondary font-[14px] whitespace-nowrap"
        >
            {children}
        </div>
    )
}

export default CategoriesMask;