function IconWrapper({Icon}) {
    return(
        <Icon
            width="50"
            height="50"
            classes="flex flex-shrink-0 p-3 border rounded-[10px] border-gray-lidabro-border-3 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
        />
    )
}

export default IconWrapper;