import { LidabroLogo } from "../LidabroLogo/LidabroLogo";
import Button from "../Button/Button";
import LangSwitcher from "../LangSwitcher/LangSwitcher";
import { useTranslation } from "react-i18next";
function Header() {
    const {t} = useTranslation();
    return (
        <header className="flex justify-between items-center pl-4 pr-3 py-1 border-b-gray-lidabro-border-3 border-b">
            <LidabroLogo/>
            <div className="flex w-1/2 gap-3 justify-end">
                <Button classes={'hidden md:flex'} mode={'secondary'}>
                    {t("Become a Seller")}
                </Button>
                <Button classes={'hidden md:flex'} mode={'primary'}>
                    {t("Try Beta")}
                </Button>
                <LangSwitcher/>
            </div>
        </header>
    )
}
export default Header;
