import { useContext } from "react";
import classNames from "classnames";
import { LanguageContext } from '../../../../../landing/Components/HOC/Language';
import { useTranslation } from "react-i18next";

function ButtonLang({ name, icon, id }) {
    const { i18n } = useTranslation();
    const { language, changeLanguage } = useContext(LanguageContext);

    function handleClick() {
        changeLanguage(id);
        i18n.changeLanguage(id);
    }

    const divClasses = classNames(
    "flex flex-row items-center cursor-pointer p-[5px] gap-[10px]",
    {
        "bg-gray-lidabro-language": language === id,
        "opacity-30": language !== id,
    }
    );

    return (
    <button id={id} className={divClasses} onClick={handleClick}>
        {icon}
        <p className="font-secondary font-medium text-black text-nowrap cursor-pointer">{name}</p>
    </button>
    );
}

export default ButtonLang;
