import Button from "../Button/Button";
import { ArrowRight5SVG } from "../../../images/SVGAssets";
import { useTranslation } from "react-i18next";
import SaleMattersSlider from "./SaleMattersSlider";
import Title from "../Text/Title";
import SubTitle from "../Text/SubTitle";

function SaleMatters() {
  const { t } = useTranslation();
  return (
    <div className=" flex flex-col xl:flex-row lg:gap-[84px] md:gap-[46px] md:p-[54px] md:bg-black-lidabro-button rounded-[40px] ">
      <div className="flex flex-col gap-8 xl:max-w-[499px] text-white ">
        <Title text={t("Each Sale Matters")} />
        <SubTitle>
          {t(`With demo-reliant products, most “cart abandons” happen before they even
  reach the cart. And with these being higher-ticket products, each sale
  matters. Taking a few minutes to answer questions is worth the value add
  to your bottom line. Not to mention the lifetime customer value.`)}
          <br /> <br />
          {t(`Now imagine a global team of trained sales specialists ready to demonstrate
  your product to shoppers 24/7. Ready - and able - to close the sale
  again and again. And do so in a way that gives you happy, loyal
  customers for life.`)}
          <br /> <br />
          {t(`That’s the power of Lidabro! Sign up now to be
  included in Lidabro’s beta.`)}
        </SubTitle>
        <Button
          icon={<ArrowRight5SVG />}
          classes={"lg:w-full lg:max-w-[251px]"}
        >
          {t("Join as a Seller?")}
        </Button>
      </div>
      <SaleMattersSlider />
    </div>
  );
}
export default SaleMatters;
