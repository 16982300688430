import classNames from "classnames";
import { AlertCircle } from "../../images/SVGAssets";
function InputFieldDashed({ name, id, placeholder, value, onChange, maxlength = "", state = InputFieldDashedStates.NORMAL, onFocus = () => {}, onBlur= () => {}, disabled = false }) {

    const wrapperStyle = classNames(
        "w-full px-3 py-[10px] border rounded-lg relative flex items-center",
        {"border-gray-lidabro-border-2 border-dashed": state === InputFieldDashedStates.NORMAL},
        {"border-red-lidabro-error": state === InputFieldDashedStates.ERROR}
    )

    return (
        <div
            className={wrapperStyle}
        >
            <input
                className="w-full placeholder:font-secondary placeholder:text-sm placeholder:font-medium placeholder:text-gray-lidabro-text-2 outline-none"
                type="text"
                name={name}
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                maxLength={maxlength}
                onFocus={onFocus}
                disabled={disabled}
                onBlur={onBlur}
            />
            {state === InputFieldDashedStates.ERROR && (<AlertCircle
                width="20"
                height="20"
                className="absolute right-4"
            />)}
        </div>
    );
}

export const InputFieldDashedStates = Object.freeze({
    ERROR: 'error',
    NORMAL: 'normal',
})

export default InputFieldDashed;
