import { useState } from "react";
import {
  ArrowLefttSliderSVG,
  ArrowRightSliderSVG,
} from "../../../images/SVGAssets";

import SaleMattersArrow from "./SaleMattersArrow";

function SaleMattersSlider() {
  const SLIDES = [
    {
      id: 1,
      url: "/images/landingSellers/happy-user2.png",
      altText: "Slide 1",
    },
    {
      id: 2,
      url: "/images/landingSellers/happy-user2.png",
      altText: "Slide 2",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev === 0 ? SLIDES.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev === SLIDES.length - 1 ? 0 : prev + 1));
  };
  return (
    <div className="hidden md:flex gap-[10px] items-center justify-center">
      <SaleMattersArrow callback={handlePrev}>
        <ArrowLefttSliderSVG />
      </SaleMattersArrow>
      <div className="flex items-center justify-center xmd:max-w-[640px] xl:max-w-[541px] xl:h-full  w-full">
        <img
          src={SLIDES[currentSlide].url}
          alt={SLIDES[currentSlide].altText}
          className="rounded-[20px] w-full h-full object-cover"
        />
      </div>
      <SaleMattersArrow callback={handleNext}>
        <ArrowRightSliderSVG />
      </SaleMattersArrow>
    </div>
  );
}

export default SaleMattersSlider;
