import BasketListItems from "../components/BasketListItems";
import BasketSubtotal from "../components/BasketSubtotal";
import PromocodeSection from "./PromocodeSection";
import { placeAnOrderBodyMania, createAnOrder } from "../../../functions/fetch";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { PromocodeContext } from "../../../contexts/PromocodeContext";


function PlaceOrderSection({basketItems, openRedirectNotification, setRedirectUrl}) {
    const {appliedPromocode} = useContext(PromocodeContext)
    const [isLoading, setIsLoading] = useState(false)
    const {t} = useTranslation();

    return (
        <div className="flex flex-col gap-3 sm:gap-6 rounded-[15px] sm:px-6 sm:py-6 sm:bg-gray-lidabro-select-background">
            <BasketListItems items={basketItems} />
            <PromocodeSection
            />
            <div className="border-b border-gray-lidabro-border-3"></div>
            <BasketSubtotal items={basketItems} appliedPromocode={appliedPromocode}/>
            <button
                disabled={isLoading}
                className="flex items-center justify-center px-[22px] py-4 rounded-lg bg-blue-lidabro-button md:bg-gray-lidabro-breadcrumb shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] font-secondary text-base font-semibold text-white text-opacity-90"
                onClick={async () => {
                    if (!Cookies.get("placeOrderClicked")) {
                        ym(
                            98895916,
                            "reachGoal",
                            "PlaceOrder",
                            {
                                session: `${Cookies.get(
                                    "sessionId"
                                )}`,
                            }
                        );
                        Cookies.set(
                            "placeOrderClicked",
                            "true",
                            {
                                expires: 365,
                            }
                        );
                    }

                    openRedirectNotification();
                    setIsLoading(true)
                    try {
                        const {newOrder} = await createAnOrder();
                        // console.log('resData:', newOrder)
                        
                    } catch (error) {
                        console.log(error)
                    }
                    finally {
                        setIsLoading(false)
                    }
                }}
            >
                {t("Place an order")}
            </button>
        </div>
    )
}

export default PlaceOrderSection;