import classNames from "classnames";

const FooterLink = ({ text, link, classes, children }) => {
  const style = classNames(
    "px-4 py-[10px] font-secondary text-base text-gray-lidabro-placeholder cursor-pointer",
    classes
  );

  return (
    <a src={link} className={style}>
      {text}
      {children}
    </a>
  );
};

export default FooterLink;
