import { useTranslation } from "react-i18next";
import Title from "../Text/Title";
import ShownItem from "../Info/ShownItem";
import {
  ChartBreakoutSquareSVG,
  AnnouncementSVG,
  IncreaseMoneySVG,
  HurricaneSVG,
  IntersectSquareSVG,
  DollarCoinSVG,
  ArrowRight5SVG,
} from "../../../images/SVGAssets";
import Button from "../Button/Button";
function Advantages() {
  const { t } = useTranslation();
  return (
    <div className=" px-6 md:p-[54px] md:bg-gray-lidabro-select-background rounded-[40px] flex gap-6 sm:gap-[26px] md:gap-[54px] xmd:gap-[48px] xml:gap-[84px] flex-col w-full">
      <Title text={t("With Lidabro, you")} classes={"text-black"} />
      <div className="w-full md:grid md:grid-cols-2 flex flex-row gap-[18px] overflow-x-auto scrollbar-none ">
        <ShownItem
          icon={<IncreaseMoneySVG />}
          text={t("Move hard-to-sell products faster")}
          classes={"min-w-[224px] md:min-w-[210px]"}
        />
        <ShownItem
          icon={<IntersectSquareSVG />}
          text={t("Increase engagement and loyalty")}
          classes={"min-w-[224px] md:min-w-[210px]"}
        />
        <ShownItem
          icon={<ChartBreakoutSquareSVG />}
          text={t("Boost conversions")}
          classes={"min-w-[224px] md:min-w-[210px]"}
        />
        <ShownItem
          icon={<DollarCoinSVG />}
          text={t("Optimize every sales channel")}
          classes={"min-w-[224px] md:min-w-[210px]"}
        />
        <ShownItem
          icon={<AnnouncementSVG />}
          text={t("Reach a wider audience")}
          classes={"min-w-[224px] md:min-w-[210px]"}
        />
        <ShownItem
          icon={<HurricaneSVG />}
          text={t("Optimize every sales channel")}
          classes={"min-w-[224px] md:min-w-[210px]"}
        />
      </div>
      <Button icon={<ArrowRight5SVG />} classes={"w-full lg:max-w-[188px]"}>
        {t("Join as a Seller?")}
      </Button>
    </div>
  );
}
export default Advantages;
