
import { FacebookSVG } from "../../../../images/SVGAssets";
import { LidabroLogo } from "../../Components/LidabroLogo/LidabroLogo";
import {Footer, FooterLink} from "../../Components/Footer/Footer";
import { useTranslation } from "react-i18next";

function FooterSection() {
    const {t} = useTranslation();
    return (
        <Footer>
            <LidabroLogo
                classes="hidden lg:flex"
            />
            <div
                className="hidden lg:flex gap-4"
            >
                <FooterLink
                    classes=""
                >
                    {t("Become a Seller")}
                </FooterLink>
                <FooterLink
                    classes=""
                >
                    {t("Contact Us")}
                </FooterLink>
            </div>
            
            <FooterLink
                classes="lg:hidden"
            >
                {t("Become a Seller")}
            </FooterLink>
            <FooterLink
                classes="lg:hidden"
            >
                {t("Contact Us")}
            </FooterLink>
            <FooterLink
                classes="hidden lg:flex"
            >
                © 2024 Lidabro. All rights reserved.
            </FooterLink>
            <FooterLink>
                <FacebookSVG
                    className="bg-gray-lidabro-placeholder scale-[2] rounded-full" 
                />
            </FooterLink>
        </Footer>
    )
}

export default FooterSection;