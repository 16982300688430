import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { PromocodeContext } from "../../../contexts/PromocodeContext";
import AppliedPromocode from "../components/AppliedPromocode";
import PromocodeStatusMessage from "../components/PromocodeStatusMessage";
import classNames from "classnames";
import PromocodeInput from "../components/PromocodeInput";


function PromocodeSection() {
    const {attachPromocode} = useContext(PromocodeContext)
    const {t} = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);

    const inputWrapperStyle = classNames(
        "flex flex-col transition-all duration-700 ease-linear relative",
        {"w-3/4": isExpanded === true},
        {"w-full": isExpanded === false},
    )

    const buttonStyle = classNames(
        "flex items-center justify-center rounded-lg font-secondary text-sm font-semibold transition-all duration-700 ease-linear overflow-hidden", 
        {"w-1/4 ml-1 block border-gray-lidabro-border-3 border text-gray-lidabro-breadcrumb shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]": isExpanded === true},
        {"w-0": isExpanded === false}
    )

    return (
        <div>
            <div className="flex flex-row">
                <div
                    className={inputWrapperStyle}
                >
                    <PromocodeInput
                        setIsExpanded={setIsExpanded}    
                        isExpanded={isExpanded}
                    />
                    <PromocodeStatusMessage/>
                </div>
                <button 
                    className={buttonStyle}
                    onClick={attachPromocode}
                    >
                    {t("Apply")}
                </button>
            </div>            
            <AppliedPromocode/>
        </div>
    )
}

export default PromocodeSection;