import Header from "./Components/Header/Header";
import HowItWorksSection from "./Sections/HowItWorksSection/HowItWorksSection";
import GoodsSection from "./Sections/GoodsSection/GoodsSection";
import BlackSection from "./Sections/BlackSection/BlackSection";
import FooterSection from "./Sections/FooterSection/FooterSection";
import AnimatedSection from "./Sections/AnimatedSection/AnimatedSection";
import { Context } from '../../landing/Components/HOC/Language'

function MyLanding() {
    return (
        <Context>
            <section style={{backgroundSize:'30px 30px'}} className="flex flex-col bg-dots-pattern bg-repeat  w-full h-full min-h-screen max-h-screen overflow-hidden relative z-10">
                <Header/>
                <AnimatedSection/>
            </section>
            <HowItWorksSection/>
            <GoodsSection/>
            <BlackSection/>
            <FooterSection/>
        </Context>
    )
}

export default MyLanding;