import { Context } from "../landing/Components/HOC/Language";
import Header from "../pages/Landing/Components/Header/Header";
import Info from "../landingSellers/Components/Info/Info";
import SaleMatters from "../landingSellers/Components/SaleMatters/SaleMatters";
import Advantages from "../landingSellers/Components/Advantages/Advantages";
import Form from "../landingSellers/Components/Form/Form";
import FormImage from "../landingSellers/Components/Form/FormImage";
import Footer from "../landingSellers/Components/Footer/Footer";
import HeroSection from "../landingSellers/Components/HeroSection/HeroSection";
import { GradientLineSVG } from "../images/SVGAssets";

function LandingContainer() {
  return (
    <Context>
      <div className="flex flex-col relative h-screen">
        <Header logoText={"For Sellers"} />
        <section className="relative h-full bg-dots-pattern bg-repeat bg-dots bg-blue-lidabro-landing-hero">
          <GradientLineSVG className="absolute w-full h-full pointer-events-none" />
          <HeroSection />
        </section>
      </div>
      <section className="max-w-lidabro-web w-full mx-auto px-6 py-[54px] md:px-[54px]">
        <Info />
      </section>
      <div className="bg-blue-lidabro-button">
        <section className="max-w-lidabro-web p-[54px] w-full mx-auto">
          <SaleMatters />
        </section>
      </div>
      <section className="max-w-lidabro-web w-full mx-auto px-6 py-[54px] md:px-[54px] bg-gray-lidabro-select-background md:bg-white">
        <Advantages />
      </section>
      <section className="max-w-lidabro-web w-full mx-auto px-[54px] py-[54px] md:py-0  ">
        <div className="md:flex md:gap-[49px] md:border-y md:border-gray-lidabro-border-3">
          <Form />
          <FormImage />
        </div>
      </section>
      <Footer />
    </Context>
  );
}
export default LandingContainer;
