import classNames from "classnames";

function Plug({isLeft = true}) {
    const style = classNames(
        'absolute top-0 bottom-0 w-5 md:w-14 xmd:w-16 lg:w-[128px] bg-white z-10',
        {'right-0': !isLeft},
        {'left-0': isLeft}
    )
    return (
        <div className={style}></div>
    )
}

export default Plug;