import Button from "../../Components/Button/Button";
import Title from "../../Components/Title/Title";
import AboutCategories from "../../../../landing/Components/About/AboutCategories";
import Plug from "./components/Plug";
import { useTranslation } from "react-i18next";

function GoodsSection() {
    const {t} = useTranslation();
    return (
        <section className="pt-14 flex flex-col justify-center items-center overflow-hidden">
            <article className="flex flex-col px-8 justify-center items-center gap-y-5 max-w-[768px]">
                <Button
                    mode={'third'}
                >
                    <span
                    className="text-[14px] text-blue-lidabro-text"
                    >
                        {t("More Categories Coming Soon!")}
                    </span>
                </Button>
                <Title>
                    <span className="flex text-center">
                        {t("What can you buy with Lidabro?")}
                    </span>
                </Title>
                <p
                    className="font-secondary text-[14px] text-center xl:text-[18px]"
                >
                    {t("Shopping with Lidabro feels like you’re in the store with a dedicated customer service representative. Because you are. Just click to request a live 1:1 demo, and let the magic begin!")}
                </p>
            </article>
            <section className="px-6 md:px-20 h-fit">
                <div className="relative text-red-600 pb-8 md:pb-16">
                    <Plug/>
                    <AboutCategories
                    categoryNameClasses="font-secondary text-black-lidabro-select-text text-[20px]"
                    />
                    <Plug
                        isLeft={false}
                    />
                </div>
            </section>
        </section>
    )
}
 
export default GoodsSection;