import Button from "../Button/Button";
import { ArrowRight5SVG } from "../../../images/SVGAssets";
import { useTranslation } from "react-i18next";
import Title from "../Text/Title";
import SubTitle from "../Text/SubTitle";

function InfoText() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-8 xmd:max-w-[380px] xl:max-w-[545px] text-black">
      <Title text={t("Bring offline level of conversion to online shopping")} />
      <SubTitle>
        {t(`Some products sell poorly online because they need that personal
        demonstration factor to close the deal. By integrating the Lidabro
        marketplace into their sales channels, online retailers can personally
        serve customers around the world from one virtual storefront,
        demonstrating their demo-reliant products 1:1. Which translates directly
        into higher conversion with lower overhead.`)}
      </SubTitle>
      <div className="max-w-[188px] mt-auto hidden xmd:block">
        <Button icon={<ArrowRight5SVG />}>{t("Join as a Seller?")}</Button>
      </div>
    </div>
  );
}

export default InfoText;
