import { useRef, useState, useEffect } from "react";
import classNames from "classnames";

function DropdownButtonsList({
    icon = '',
    children = '',
    classes,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);
    const openRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen((prev) => !prev);
    };

    const handleOutsideClick = (event) => {
        if(openRef.current && openRef.current.contains(event.target)) {
            return;
        }
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const style = classNames(
       "absolute w-fit right-3 bg-white border border-gray-300 rounded-md shadow-lg flex flex-col gap-1 p-2",
       classes
      );

    return (
        <div className="relative inline-block z-10">
            <button
                ref={openRef}
                onClick={toggleDropdown}
            >
                {icon}
            </button>

            {isOpen && (
                <div ref={ref} className={style}>
                    {children}
                </div>
            )}
        </div>
    );
}

export default DropdownButtonsList;