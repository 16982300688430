import classNames from "classnames"

export const Footer = ({children}) => {
    return (
        <footer className="py-6 px-8 bg-gray-lidabro-footer flex justify-center lg:justify-between items-center flex-wrap gap-6">
            {children}
        </footer>
    )
}

export const FooterLink = ({children, classes = ''}) => {

    const style = classNames(
        "px-2 py-3 font-secondary text-gray-lidabro-placeholder",
        classes
    )

    return (<a 
        src="#"
        className={style}
    >
        {children}
    </a>)
}