import { useState } from "react";
import Title from "../Text/Title";
import SubTitle from "../Text/SubTitle";
import InputField from "./InputField";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
function Form() {
  const { t } = useTranslation();
  const [form, setForm] = useState({});

  return (
    <div className="flex flex-col gap-8 sm:px-6 xmd:px-0 xmd:py-[54px] xmd:pl-[54px] ">
      <div className="flex flex-col text-black">
        <Title text={t("Let’s level up your sales, together.")} />
        <SubTitle>
          {t(
            `Lidabro is in open beta right now. Sign up to be among the first vendors included in the live-marketplace.`
          )}
        </SubTitle>
      </div>
      <form
        className="flex flex-col gap-3"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <InputField
          text={"First name"}
          type={"text"}
          placeholder={"First name"}
          id={"firstName"}
          onChange={(e) => setForm({ ...form, firstName: e.target.value })}
        />
        <InputField
          text={"Last name"}
          type={"text"}
          placeholder={"Last name"}
          id={"lastName"}
          onChange={(e) => setForm({ ...form, lastName: e.target.value })}
        />
        <InputField
          text={"Email"}
          type={"email"}
          placeholder={"you@company.com"}
          id={"email"}
          onChange={(e) => setForm({ ...form, email: e.target.value })}
        />
        <InputField
          text={"Phone number"}
          type={"tel"}
          placeholder={"+972 (555) 000-0000"}
          id={"phone"}
          onChange={(e) => setForm({ ...form, phone: e.target.value })}
        />
        <InputField
          text={"Company"}
          type={"text"}
          placeholder={"you@company.com"}
          id={"company"}
          onChange={(e) => setForm({ ...form, company: e.target.value })}
        />
      </form>
      <Button classes={"w-full"} type="submit">
        {t("Reserve your spot")}
      </Button>
    </div>
  );
}
export default Form;
