import classNames from "classnames";

function ShownItem({ icon, text, classes }) {
  
  const style = classNames(
    "w-full flex flex-col md:flex-row items-center gap-4 md:gap-8 p-6 bg-white border border-gray-lidabro-border-3 rounded-[20px]",
    classes
  );

  return (
    <div className={style}>
      <div className="p-3 border border-gray-lidabro-border-3 rounded-[10px]">
        {icon}
      </div>
      <p className="font-secondary text-base xl:text-xl xl:leading-[30px] text-center md:text-left font-semibold text-black-lidabro-select-text">
        {text}
      </p>
    </div>
  );
}

export default ShownItem;
