function SaleMattersArrow({ children, callback }) {
  return (
    <div
      className="w-[40px] h-[40px] flex items-center px-3 py-[10px] rounded-full cursor-pointer bg-black-lidabro-arrow "
      onClick={callback}
    >
      {children}
    </div>
  );
}
export default SaleMattersArrow;
