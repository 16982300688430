import InfoText from "./InfoText";
import ShownItem from "./ShownItem";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import {
  ArrowDownSVG,
  ChartBreakoutSquareSVG,
  DollarCoinSVG,
  ShoppingBag3SVG,
  UsersUpSVG,
  ArrowRight5SVG,
} from "../../../images/SVGAssets";

function Info() {
  const { t } = useTranslation();

  return (
    <div className=" px-6 md:p-[54px] md:bg-gray-lidabro-select-background rounded-[40px] flex gap-6 sm:gap-[26px] md:gap-[54px] xmd:gap-[48px] xml:gap-[84px] flex-col xmd:flex-row">
      <InfoText />
      <div className="flex flex-col gap-4">
        <div className="items-center gap-3 hidden xmd:flex">
          <ArrowDownSVG />
          <p className="font-secondary text-base font-semibold text-gray-lidabro-text-2">
            {t("Implementing Lidabro has shown")}
          </p>
        </div>
        <div className="flex flex-col sm:flex-row md:flex-col gap-5 md:gap-[5px]">
          <ShownItem
            icon={<ChartBreakoutSquareSVG />}
            text={t("An average of 28% conversion increase")}
          />
          <ShownItem
            icon={<ShoppingBag3SVG />}
            text={t("Higher Average Basket Size")}
          />
          <ShownItem
            icon={<UsersUpSVG />}
            text={t("Better engagement and customer retention")}
          />
          <ShownItem
            icon={<DollarCoinSVG />}
            text={t("Much lower costs relative to offline sales")}
          />
        </div>
      </div>
      <div className="xmd:hidden">
        <Button icon={<ArrowRight5SVG />} classes={"w-full"}>
          {t("Join as a Seller?")}
        </Button>
      </div>
    </div>
  );
}
export default Info;
