import DropdownButtonsList from '../../../../components/DropdownButtonsList/DropdownButtonsList';
import ButtonLang from "./components/ButtonLang"
import { LangIcon } from "../Icons/Icons"

function LangSwitcher() {
    return (
        <DropdownButtonsList
          icon={<LangIcon />}
          classes={"top-[60px] md:top-[80px]"}
        >
          <ButtonLang
            name={"English"}
            icon={<LangIcon />} // TODO icon
            id={"en"}
          />
          <ButtonLang
            name={"Hebrew"}
            icon={<LangIcon />} // TODO icon
            id={"iw"}
          />
        </DropdownButtonsList>
    )
}

export default LangSwitcher;