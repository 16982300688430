import { GradientLineSVG2 } from "../../../images/SVGAssets";
import AvatarGroup from "./AvatarGroup";
import HeroSectionSubtitle from "./HeroSectionSubtitle";
import Button from "../Button/Button";
import { ArrowRight5SVG, EyeSVG } from "../../../images/SVGAssets";
import { useTranslation } from "react-i18next";
function HeroSection() {
  const { t } = useTranslation();
  return (
    <div className="lg:max-w-[1040px] md:max-w-[821px] px-8  w-full h-full mx-auto flex flex-col items-center justify-center text-white">
      <h3 className="relative mb-8 sm:mb-9 sm:text-center font-semibold leading-[38px] sm:leading-[72px] tracking-[-1px] sm:text-[60px] text-2xl font-secondary bg-text-gradient bg-clip-text text-transparent ">
        Boost{" "}
        <img
          src="/images/landingSellers/chartMini.png"
          alt="Chart Mini"
          className="hidden sm:inline-block"
        />{" "}
        conversion by
        <span className="inline relative text-white">
          {" "}
          28%{" "}
          <GradientLineSVG2 className="absolute bottom-[2px] left-0 hidden sm:inline" />
        </span>
        when you{" "}
        <span className="text-white">
          humanize <AvatarGroup />
          online shopping
        </span>{" "}
        with Lidabro{" "}
        <img
          src="/images/landingSellers/chartMini.png"
          alt="Chart Mini"
          className="absolute bottom-[10px] right-[0px] xs:hidden"
        />
      </h3>
      <HeroSectionSubtitle />
      <div className="flex gap-3 flex-col sm:flex-row w-full md:w-auto">
        <Button
          icon={<EyeSVG fill="white" />}
          mode="transparent"
          classes={"w-full md:w-auto"}
        >
          {t("Request Demo")}
        </Button>
        <Button
          icon={<ArrowRight5SVG />}
          mode="white"
          classes={"w-full md:w-auto"}
        >
          {t("Join as a Seller?")}
        </Button>
      </div>
    </div>
  );
}
export default HeroSection;
