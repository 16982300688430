function FormImage() {
  return (
    <div className="hidden md:block w-full h-auto xl:max-w-[615px]">
      <img
        className="w-full h-full object-cover"
        src="/images/landingSellers/form-image.png"
        alt="Form"
      />
    </div>
  );
}
export default FormImage;
