import Button from "../../Components/Button/Button";
import { ArrowRightIcon } from "../../../../landing/Components/Shared/SVGComponents";
import CategoriesMask from './components/CategoriesMask/CategoriesMask';
import { useTranslation } from "react-i18next";

function BlackSection() {
    const {t} = useTranslation()
    return (
        <section 
            className="px-16 flex flex-col justify-center items-center bg-custom-gradient w-full h-screen blend-overlay shadow-custom-inset relative overflow-hidden z-[-2]"
        >
            <h3
                className="text-white px-6 lg:w-1/2  text-[18px] font-main font-semibold text-center pb-9 xsm:pb-16 leading-7 xsm:text-[24px] xsm:leading-8 lg:text-[30px] lg:leading-9"
            >
                {t("Lidabro is doing a soft launch, but already you can try how it works in the sports category.")}
            </h3>
            <Button mode={'forth'}>
                <span className="flex w-fit font-secondary text-[16px] md:text-[18px] leading-5 xsm:leading-7 whitespace-nowrap">
                    {t("Try Lidabro Beta now!")}
                </span>
                <ArrowRightIcon/>
            </Button>
            <CategoriesMask/>
            <div class="absolute inset-0 bg-black opacity-30 z-[-1]"></div>
        </section>
    )
}

export default BlackSection;
