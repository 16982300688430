import { useTranslation } from "react-i18next";

function BasketSubtotal(params) {
    const { t } = useTranslation();
    const {appliedPromocode} = params;

    function calcPriceOfProducts(items) {
        const {totalPrice, totalPromocodeDiscountByProduct} = items.reduce((accumulator, currentValue) => {
            if (currentValue.item) { 
                const currentValuePrice = currentValue.item.discountPrice ? currentValue.item.discountPrice.price : currentValue.item.actualPrice.price;
                const {price, promocodeDiscount} = getProductPriceWithPromocodeDiscount(currentValue.item.id, appliedPromocode, currentValuePrice);
                accumulator.totalPrice = accumulator.totalPrice + price * currentValue.quantity
                accumulator.totalPromocodeDiscountByProduct = accumulator.totalPromocodeDiscountByProduct + promocodeDiscount * currentValue.quantity
                return accumulator;
            } else {
                accumulator.totalPrice = accumulator.totalPrice + 9999 * currentValue.quantity
                return accumulator;
            }
        }, {totalPrice: 0, totalPromocodeDiscountByProduct: 0});
        const {price, discountOnTotal } = applyPromocodeTotalDiscaunt(totalPrice, appliedPromocode)
        
        return {
            total: Math.round(price * 100) / 100,
            promocodeDiscount: totalPromocodeDiscountByProduct + discountOnTotal
        }
    }

    function calcSubtotal(items) {
        const {total} = calcPriceOfProducts(items);
        if (total < 0) {
            return 0;
        }
        const roundedTotal = Math.round(total * 100) / 100;
        return roundedTotal;
    }

    function calcSubtotalItems(items) {
        const total = items.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.quantity;
        }, 0);
        const roundedTotal = Math.round(total * 100) / 100;
        return roundedTotal;
    }

    function calcSubtotalDiscount(items) {
        const total = items.reduce((accumulator, currentValue) => {
            if (!currentValue.item.hasOwnProperty("discountPrice")) {
                return accumulator + 0;
            }
            const discountPrice = Boolean(currentValue.item.discountPrice) ? currentValue.item.discountPrice.price : 0;
            return (
                accumulator +
                (currentValue.item.actualPrice.price -
                    discountPrice) *
                    currentValue.quantity
            );
        }, 0);
        const roundedTotal = Math.round(total * 100) / 100;
        return roundedTotal;
    }

    function applyPromocodeTotalDiscaunt(price, appliedPromocode) {
        const result = {price, discountOnTotal: 0};
        if (!appliedPromocode?.discount?.total) {
            return result;
        }
        if(appliedPromocode.discount.total.type === '-') {
            result.price = price - appliedPromocode.discount.total.value;
            result.discountOnTotal = appliedPromocode.discount.total.value;
        }
        if(appliedPromocode.discount.total.type === '%') {
            result.price = price - (price * appliedPromocode.discount.total.value / 100);
            result.discountOnTotal = price * appliedPromocode.discount.total.value / 100;
        }
        return result;
    }

    function findPromocodeDiscountByProduct(productId, appliedPromocode) {
        if (!appliedPromocode?.discount?.products) {
            return null;
        }
        if(productId in appliedPromocode.discount.products) {
            return appliedPromocode.discount.products[productId]
        }
        return null;
    }

    function getProductPriceWithPromocodeDiscount(productId, appliedPromocode, rawPrice) {
        const result = {price: rawPrice, promocodeDiscount: 0}
        const discount = findPromocodeDiscountByProduct(productId, appliedPromocode);
        
        if(!discount) {
            return result;
        }

        const {type, value} = discount;
        if(type === '-') {
            result.price = rawPrice - value
            result.promocodeDiscount = value;
        }
        if(type === '%') {
            result.price = rawPrice - (rawPrice * value / 100)
            result.promocodeDiscount = rawPrice * value / 100;
        }
        return result;
    }

    const {total: priceOfGoods, promocodeDiscount} = calcPriceOfProducts(params.items)
    const discount = calcSubtotalDiscount(params.items)
    const subtotalItems = calcSubtotalItems(params.items);
    const subtotal = calcSubtotal(params.items, discount)

    return (
        <>
            <div className="flex flex-col gap-3">
                <div className="flex justify-between">
                    <p className="font-secondary text-base font-medium text-gray-lidabro-text">
                        {t("Subtotal")} ({subtotalItems}{" "}
                        {params.items.length > 1 ? t("items") : t("item")})
                    </p>
                    <p className="font-secondary text-base font-medium text-gray-lidabro-text">
                        {subtotal} ₪
                    </p>
                </div>
                <div className="flex gap-3">
                    <p className="font-secondary text-xs font-medium leading-[18px] text-gray-lidabro-placeholder text-nowrap">
                        {t("Price of products")}
                    </p>
                    <div className="flex grow border-b border-dashed border-gray-lidabro-border-2"></div>
                    <p className="font-secondary text-sm font-medium text-gray-lidabro-placeholder">
                        {priceOfGoods} ₪
                    </p>
                </div>
                <div className="flex gap-3">
                    <p className="font-secondary text-sm font-medium text-green-lidabro-text">
                        {t("Discount")}
                    </p>
                    <div className="flex grow border-b border-dashed border-green-lidabro-text"></div>
                    <p className="font-secondary text-sm font-medium text-green-lidabro-text">
                        -{discount} ₪
                    </p>
                </div>
                {
                    appliedPromocode && (
                        <div className="flex gap-3">
                            <p className="font-secondary text-sm font-medium text-green-lidabro-text">
                                {t("Promocode discount")}
                            </p>
                            <div className="flex grow border-b border-dashed border-green-lidabro-text"></div>
                            <p className="font-secondary text-sm font-medium text-green-lidabro-text">
                                -{promocodeDiscount.toFixed(2)} ₪
                            </p>
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default BasketSubtotal;
