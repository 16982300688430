function StyledParagraph({children}) {
    return (
        <p
            className="font-secondary font-semibold text-black text-[16px] lg:text-[20px] text-center xsm:text-left"
        >
            {children}
        </p>
    )
}

export default StyledParagraph;