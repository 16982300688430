import AnimatedText from "./components/AnimatedText/AnimatedText"
import Button from "../../Components/Button/Button"
import { ArrowRightIcon } from "../../../../landing/Components/Shared/SVGComponents"
import { useTranslation } from "react-i18next"
function AnimatedSection() {
    const {t} = useTranslation();
    return (
        <>
            <section className="flex-grow grid grid-rows-[2fr-1fr]">
                <div className="grid grid-rows-3 px-8 flex-none h-full">
                    <AnimatedText/>
                    <div className="flex row-span-1 flex-col justify-evenly items-center">
                        <p className="flex text-center font-medium md:font-normal font-secondary mb-8 md:text-[24px]">
                            {t("See a product. Request a 1:1 demo. Get all questions answered.")}
                        </p>
                        <Button mode={'primary'}>
                            <span className="font-secondary text-[16px] lg:text-[18px]">
                                {t("Try Lidabro Beta")}
                            </span>
                            <ArrowRightIcon/>
                        </Button>
                    </div>
                </div>
                
                <div className="flex mt-8 md:mb-8 md:mt-4 justify-center items-center gap-2 flex-none overflow-hidden w-auto h-full max-w-none max-h-none">
                    <img className="w-[200px] h-[300px]" src='/images/Widget_window_1.png'/>
                    <img className="w-[200px] h-[300px]" src='/images/Widget_window_2.png'/>
                    <img className="w-[422px] h-[429px] hidden lg:block" src='/images/Widget_window_5.png'/>
                    <img className="w-[200px] h-[300px] hidden md:block" src='/images/Widget_window_3.png'/>
                    <img className="w-[200px] h-[300px]" src='/images/Widget_window_4.png'/>
                </div>
            </section>
            <svg className="absolute w-full h-screen z-[-1]" xmlns="http://www.w3.org/2000/svg" width="768" height="731" viewBox="0 0 768 731" fill="none">
                <g filter="url(#filter0_f_6358_44830)">
                    <path d="M1075.72 1110.49C499.904 859.192 -13.4158 192.85 -198.099 -108.909C233.268 486.694 1305.75 846.66 1788.07 952.193C1790.54 1109.67 1651.53 1361.79 1075.72 1110.49Z" fill="url(#paint0_linear_6358_44830)"/>
                </g>
                <defs>
                    <filter id="filter0_f_6358_44830" x="-311.299" y="-222.109" width="2212.6" height="1552.22" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="56.6" result="effect1_foregroundBlur_6358_44830"/>
                    </filter>
                    <linearGradient id="paint0_linear_6358_44830" x1="795" y1="-108.909" x2="795" y2="1216.91" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#90FFDC"/>
                    <stop offset="1" stop-color="#96ACFF"/>
                    </linearGradient>
                </defs>
            </svg>
        </>
    )
}

export default AnimatedSection;