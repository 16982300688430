function InputField({ text, type, placeholder, id, onChange }) {
  return (
    <div className="flex flex-col gap-[6px]">
      <label
        htmlFor={id}
        className="text-sm text-gray-lidabro-text font-medium"
      >
        {text}
      </label>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        className="py-2.5 px-3.5 text-base leading-6 text-secondary text-gray-lidabro-placeholder bg-white border border-gray-lidabro-border-2 rounded-lg shadow-select-shadow-3 text-ellipsis appearance-none"
        onChange={onChange}
      />
    </div>
  );
}
export default InputField;
