import classNames from "classnames";

export default function Button({
  children,
  icon,
  classes,
  type = "submit",
  callback,
  mode = "neon",
}) {
  const style = classNames(
    " relative flex justify-center items-center gap-2 font-secondary text-base font-semibold py-3 px-[18px] whitespace-nowrap rounded-md pointer z-10",
    classes,
    {
      " border border-green-lidabro-neon bg-green-lidabro-neon text-black-lidabro-review shadow-xs hover:bg-green-lidabro-text":
        mode === "neon",
    },
    {
      "bg-white border-gray-lidabro-border-2 hover:bg-gray-lidabro-select-background focus:bg-white focus:shadow-select-shadow-2 text-gray-lidabro-breadcrumb-bold":
        mode === "white",
    },
    {
      "border border-white text-white": mode === "transparent",
    }
  );

  return (
    <button className={style} type={type} onClick={callback}>
      {children}
      {icon}
    </button>
  );
}
