import { createContext, useState } from "react";
import { applyPromocode } from "../functions/fetch";
import { useTranslation } from "react-i18next";

export const PromocodeContext = createContext();

const defaultPromocodeInfo = {code: '', state: ''};

export const PromocodeProvider = ({children}) => {
    const {t} = useTranslation()
    const [promocode, setPromocode] = useState(structuredClone(defaultPromocodeInfo));
    const [appliedPromocode, setAppliedPromocode] = useState(null)

    const attachPromocode = async() => {
        const data = await applyPromocode({promocode: promocode.code});

        if(data.message === PromocodeStates.VALID) {
            setAppliedPromocode(structuredClone(data))
            setPromocode(structuredClone(defaultPromocodeInfo))
        }
        else {
            setPromocode((prev) => {
                const newPromocode = structuredClone(prev);
                newPromocode.state = data.message;
                return newPromocode;
            })
        }
    }

    const removeAppliedPromocode = () => {
        setAppliedPromocode(null)
        setPromocode(structuredClone(defaultPromocodeInfo))
    }

    const removePromocode = () => {
        setPromocode(structuredClone(defaultPromocodeInfo))
    }

    const updatePromocodeCode = (text) => {
        setPromocode((prev) => {
            const newPromocode = structuredClone(prev);
            newPromocode.code = text;
            return newPromocode;
        })
    }

    const PromocodeStates = Object.freeze({
        VALID: "valid",
        EXPIRED: "expired",
        NOT_STARTED: "not_started",
        ALREADY_USED: "already_used",
        NOT_FOUND: "not_found",
    });

    const PromocodeStateMessage = Object.freeze({
        [PromocodeStates.VALID] : t("This promocode is applyed"),
        [PromocodeStates.EXPIRED] : t("This promocode is expired"),
        [PromocodeStates.NOT_STARTED] : t("This promocode hasn't started yet"),
        [PromocodeStates.ALREADY_USED] : t("This promocode is used"),
        [PromocodeStates.NOT_FOUND] : t("This promocode isn't found"),
    })

    return (
        <PromocodeContext.Provider
            value = {{
                promocode,
                attachPromocode,
                removePromocode,
                updatePromocodeCode,
                appliedPromocode,
                PromocodeStates,
                PromocodeStateMessage,
                removeAppliedPromocode
            }}
        >
            {children}
        </PromocodeContext.Provider>
    )
}