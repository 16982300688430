import classNames from "classnames";

export default function Button({ children, mode, classes }) {
    const styleSeller = classNames(
        "flex justify-center items-center font-secondary text-base font-semibold px-4 py-2 whitespace-nowrap",
        {
            "rounded-md border border-blue-lidabro-button bg-blue-lidabro-button text-white-lidabro shadow-xs hover:bg-blue-lidabro-text focus:bg-blue-lidabro-button focus:shadow-select-shadow":
                mode === "primary",
        },
        {
            "rounded-md border border-green-lidabro-neon bg-green-lidabro-neon text-black-lidabro-review shadow-xs hover:bg-green-lidabro-text":
                mode === "neon",
        },
        { "text-gray-lidabro-breadcrumb": mode === "secondary" },
        classes
    );

    const styleCustomer = classNames(
        "flex justify-center items-center font-secondary text-base font-semibold w-fit gap-1",
        {
            "px-4 py-2 rounded-md border border-blue-lidabro-button bg-blue-lidabro-button text-white-lidabro shadow-xs":
                mode === "primary",
        },
        { "px-4 py-2 text-gray-lidabro-breadcrumb": mode === "secondary" },
        {
            "rounded-3xl border border-blue-lidabro-border-2  bg-blue-lidabro-button-background py-0 px-3":
                mode === "third",
        },
        {
            "px-4 py-2 rounded-md border border-black bg-gray-lidabro-footer text-black ":
                mode === "forth",
        },
        classes
    );

    return <button className={style}>{children}</button>;
}
