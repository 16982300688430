import { LiveIcon, StopWatchIcon, SparklingPointerIcon, EyeIcon } from "../../Components/Icons/Icons";
import Paragraph from "../../Components/Paragraph/Paragraph";
import Title from "../../Components/Title/Title";
import Button from "../../Components/Button/Button";
import { ArrowRightIcon } from "../../../../landing/Components/Shared/SVGComponents";
import StyledLink from "./components/StyledLink";
import IconWrapper from "./components/IconWrapper";
import StyledParagraph from "./components/StyledParagraph";
import { useTranslation } from "react-i18next";

function HowItWorksSection() {
    const {t} = useTranslation()
    return (
        <section className="p-8 xsm:p-14 md:m-14 grid grid-cols-1 lg:grid-cols-2 lg:gap-x-14">
            <section className="lg:flex lg:flex-col lg:justify-center lg:items-center">
                <Title>
                    {t("How it works")}
                </Title>
                <article className="pt-8">
                    <Paragraph>
                        {t("Online shopping is fast and convenient, yet you don’t really know what you are getting. Reviews only go so far, even video ones. And what if you still have questions?")}
                    </Paragraph>
                    <br/>
                    <Paragraph>
                        {t("Lidabro brings back the human shopping experience — from the comfort of your own home. It’s as simple as possible. ")}
                    </Paragraph> 
                    <br/>
                    <Paragraph>
                        {t("Ask questions, request to show specific details of the product. You never have to turn your camera on, just watch the specialist demonstrate the product.")}
                    </Paragraph>
                </article>
            </section>
            <section className="w-full py-8 md:py-14 flex flex-col gap-1 lg:row-span-2 lg:py-0">
                <StyledLink>
                    <IconWrapper
                        Icon={EyeIcon}
                    />
                    <StyledParagraph>
                        {t("See a product you like")}
                    </StyledParagraph>
                </StyledLink>
                <StyledLink>
                    <IconWrapper
                        Icon={SparklingPointerIcon}
                    />
                    <StyledParagraph>
                        {t("Request a live demo")}
                    </StyledParagraph>
                </StyledLink>
                <StyledLink>
                    <IconWrapper
                        Icon={StopWatchIcon}
                    />
                    <StyledParagraph>
                        {t("A specialist will respond in minutes….")}
                    </StyledParagraph>
                </StyledLink>
                <StyledLink>
                    <IconWrapper
                        Icon={LiveIcon}
                    />
                    <StyledParagraph>
                        {t("…and show you the product")}
                    </StyledParagraph>
                </StyledLink>
            </section>
            <Button
                mode={'primary'}
                classes={"w-full md:w-fit md:place-self-center justify-between xsm:justify-center xsm:gap-2 lg:col-span-1"}
            >
                <div className="w-4/5 xsm:w-fit text-start font-secondary text-[14px] xsm:text-[16px]">
                    {t("Bring joy back to your online shopping!")}
                </div>
                <ArrowRightIcon/>
            </Button>
        </section>
    )
}

export default HowItWorksSection;