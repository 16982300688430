import { useContext } from "react"
import { PromocodeContext } from "../../../contexts/PromocodeContext";
function PromocodeStatusMessage() {

    const {promocode, PromocodeStateMessage} = useContext(PromocodeContext)

    if(promocode.state) {
        return (
            <p
                className="text-red-lidabro-error font-secondary font-normal text-[14px] absolute bottom-[-22px]"
            >
                {PromocodeStateMessage[promocode.state]}
            </p>
        )
    }

    return null;
}

export default PromocodeStatusMessage;