import Categories from './AboutCategoriesList.json'
import AboutCategory from './AboutCategory';
import classNames from 'classnames';

function AboutCategories({categoryNameClasses = ''}) {
    return (
        <div className="overflow-hidden w-screen">
            <div className='flex gap-4.5 animate-lidabro-slider'>
                {Categories.map((category)=><AboutCategory categoryNameClasses={categoryNameClasses} key={category.id} category={category} />)}
            </div>
        </div>
    )
}

export default AboutCategories;