import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../hook/useAuth";
import Main from "../pages/Main/Main";
import Demo from "../pages/Main/Demo";
import Container from "./Container";
import SportContainer from "../layout/SportContainer";
import Sport from "../pages/Main/Sport";
import ItemsSport from "../pages/Items/ItemsSport";
import ItemSport from "../pages/Items/ItemSport";
import LandingContainer from "../layout/LandingContainer";
import Landing from "../pages/Main/Landing";
import MyLanding from "../pages/Landing/MyLanding";
import SellersContainer from "../layout/SellersContainer";

const Routes = () => {
    const { token } = useAuth();

    const router = createBrowserRouter([
        {
            path: "/",
            element: <LandingContainer />,
            children: [
                {
                    index: true,
                    element: <Landing />,
                }
            ],
        },
        {
            path: "/myLanding",
            element: <MyLanding />,
        },
        {
            path: "/sport",
            element: <SportContainer />,
            children: [
                {
                    index: true,
                    element: <Sport />,
                },
                {
                    path: ":subdepartment",
                    element: <ItemsSport />,
                },
                {
                    path: ":subdepartment/:category",
                    element: <ItemsSport />,
                },
                {
                    path: ":subdepartment/:category/:subcategory",
                    element: <ItemsSport />,
                },
                {
                    path: "items/:item",
                    element: <ItemSport />,
                },
                {
                    path: "brands/:brand",
                    element: <ItemsSport />,
                },
                {
                    path: "search",
                    element: <ItemsSport />,
                },
            ],
        },
        {
            path: "/seller",
            element: <SellersContainer/>,
        },
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router} />;
};

export default Routes;
