import { useTranslation } from "react-i18next";
function PromocodeTooltipContent({appliedPromocode}) {
    const {t, i18n} = useTranslation()
    const {discount} = appliedPromocode;
    function getName(item) {
        return i18n.language === "en" ? item.name : item[`name_${i18n.language}`] || item.name
    }
    return (
        <div>
            {discount.total && (
                <p
                    key="total"
                    className="font-secondary"
                >
                    {t("On whole basket")}: {discount.total.type} {discount.total.value}
                </p>
            )}
            {
                discount.products && (
                    Object.keys(discount.products).map((productId) => {
                        const productDiscount = discount.products[productId]
                        return (
                            <p
                                key={productId}
                                className="font-secondary"
                            >
                                {getName(productDiscount)} : {productDiscount.type} {productDiscount.value}
                            </p>
                        )
                    })
                )
            }
        </div>
    )
}

export default PromocodeTooltipContent;