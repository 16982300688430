import { LogoIcon } from "../../../../landing/Components/Shared/SVGComponents"
import classNames from "classnames"
export function LidabroLogo({classes=''}) {
    const style = classNames(
        "flex flex-row items-center font-main md:text-base2 text-base font-bold text-black-lidabro max-h-14",
        classes
    )
    return (
    <div className={style}>
        <LogoIcon  className="scale-50 md:scale-100"/>
        <h1>Lidabro</h1>
    </div>
  );
}
